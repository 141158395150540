.imagen-container {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  
  .imagen-responsive {
    object-fit: contain;
  }
  
  @media (max-width: 1208px) {
    .imagen-responsive {
      width: 100% !important;
      height: auto !important;
    }
  }
  
  .error-message, .loading-message {
    text-align: center;
    color: #6c757d;
    font-style: italic;
  }