/* CustomModal.css */
.custom-modal {
    display: flex !important;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.custom-modal .modal-content {
    border-radius: 0;
    width: 100%;
    margin: 0;
}

.custom-modal .modal-header,
.custom-modal .modal-body,
.custom-modal .modal-footer {
    border-radius: 0;
}