#custom-spinner {
    z-index: 999999;
    background: rgba(255, 255, 255, 1);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-spinner {
    color: #FF9800 !important;
}

.spinner-wrapper p {
    margin-top: 1rem;
}