.custom-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    padding: 0;
    list-style-type: none;
  }
  
  .pagination-item {
    margin: 0 0.25rem;
  }
  
  .pagination-link {
    width: 2.1rem;
    height: 2.1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5D4037;
    font-weight: 600;
    background-color: #F5F5F5;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    padding: 0;
    margin: 0;
    font-size: 1rem;
  }
  
  .pagination-link:hover {
    background-color: #D7CCC8;
    color: #3E2723;
  }
  
  .pagination-item.active .pagination-link {
    background-color: #795548;
    color: white;
  }
  
  .pagination-link:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(121, 85, 72, 0.5);
  }
  
  /* Estilos específicos para los botones de navegación */
  .pagination-item:first-child .pagination-link,
  .pagination-item:last-child .pagination-link,
  .pagination-item:nth-child(2) .pagination-link,
  .pagination-item:nth-last-child(2) .pagination-link {
    background-color: #EFEBE9;
  }
  
  .pagination-item:first-child .pagination-link:hover,
  .pagination-item:last-child .pagination-link:hover,
  .pagination-item:nth-child(2) .pagination-link:hover,
  .pagination-item:nth-last-child(2) .pagination-link:hover {
    background-color: #D7CCC8;
  }
  
  /* Asegurar que los iconos estén centrados */
  .pagination-link svg {
    display: block;
    margin: auto;
  }
  
  /* Anular estilos de reactstrap */
  .pagination-link:active,
  .pagination-link:focus,
  .pagination-link.active {
    background-color: #795548 !important;
    color: white !important;
    border-color: transparent !important;
  }
  
  .pagination-item.active .pagination-link:hover {
    background-color: #5D4037 !important;
  }