.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
  }
  
  .not-found-container h1 {
    font-size: 8rem;
    color: #6c5a4f;
    margin-bottom: 0;
  }
  
  .not-found-container h2 {
    font-size: 2rem;
    color: #343a40;
    margin-top: 0;
  }
  
  .not-found-container p {
    font-size: 1.2rem;
    color: #6c757d;
    margin-bottom: 2rem;
  }
  
  .home-link {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #0056b3;
  }