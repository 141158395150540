html, body, #root {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.layout-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

#tabs-layout, #navbar-layout {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
}

#tabs-layout > .nav, #navbar-layout > .mobile-navbar, #navbar-layout > .mobile-breadcrumb {
    flex-shrink: 0;
}

.tab-content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.tab-content > .main-card {
    flex-grow: 1;
    overflow-y: auto;
    border: 0;
    padding: 1em;
    border-radius: 0;
    scrollbar-width: thin;
    scrollbar-color: #aaaaaa #f1f1f1;
}

.tab-content > .main-card::-webkit-scrollbar {
    width: 8px;
}

.tab-content > .main-card::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.tab-content > .main-card::-webkit-scrollbar-thumb {
    background-color: #aaaaaa;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.tab-content > .main-card::-webkit-scrollbar-thumb:hover {
    background-color: #999999;
}

#tabs-layout {
    background-color: white;
    width: 100%;
    height: 100%;
}

#tabs-layout > .nav > .nav-item > .nav-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 0 !important;
}

#tabs-layout > .nav > .nav-item .active {
    font-weight: bold;
    color: black;
    border-top: 1px #dee2e6 solid;
    border-left: 1px #dee2e6 solid;
    border-right: 1px #dee2e6 solid;
}

#tabs-layout > .nav {
    background-color: #f8f9fa !important;
    color: black;
}

#tabs-layout > .nav > .nav-item > * {
    border: 0;
    color: #4C5058;
}

#tabs-layout > .nav > .nav-item > *:hover {
    color: black !important;
    transition: color 0s !important;
}

#navbar-layout > nav {
    background-color: #f8f9fa;
}

#navbar-layout > nav .navbar-toggler {
    background-color: white;
    border: 0;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
}

#navbar-layout > nav .navbar-toggler:hover {
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.5s;
}

.mobile-navbar {
    padding: 0.75rem 1rem;
    background-color: #fff !important;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.mobile-navbar .navbar-brand {
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 0.5rem;
}

.mobile-navbar .navbar-brand .icon {
    margin-right: 10px;
}

.mobile-breadcrumb-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0.3rem;
    background-color: #f8f9fa;
    font-size: 0.9rem;
    color: #6c757d;
}

.mobile-navbar .navbar-toggler {
    border: none;
    padding: 0.25rem 0.5rem;
}

.mobile-navbar .nav-link {
    padding: 0.5rem 1rem;
    color: #495057;
}

.mobile-navbar .nav-link:hover {
    background-color: #e9ecef;
}

.parent-route-link {
    color: #865000;
    text-decoration: none;
    font-weight: bold;
    cursor: pointer;
}

.parent-route-link:hover {
    text-decoration: underline;
}

.breadcrumb-container {
    padding-left: 1em;
}

.mobile-menu {
    position: relative;
    z-index: 1000;
}

.fullscreen-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-header {
    position: absolute;
    top: 20px;
    right: 20px;
}

.menu-content {
    display: flex;
    flex-direction: column;
}

.menu-content .nav-item {
    margin: 10px 0;
    color: #6c5a4f;
    font-weight: bold;
}

.menu-content .nav-link {
    font-size: 1.2rem;
    color: #6c5a4f;
}

.mobile-user-panel {
    font-size: 22px;
    height: 50px;
    display: flex;
    align-items: center;
}

.language-buttons {
    display: flex;
    justify-content: start;
    gap: 10px;
    height: 50px;
    align-items: center;
}

.logout-button {
    display: flex;
    justify-content: start;
    height: 50px;
    align-items: center;
}

.mobile-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

#app-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.desktop-nav {
    display: flex;
}

.breadcrumb-tab {
    flex-grow: 1;
}

@media (max-width: 800px) {
    #tabs-layout {
        display: none;
    }
    
    
    .mobile-navbar .navbar-nav {
        padding-left: 0.5em;
    }
}

@media (min-width: 801px) {
    #navbar-layout {
        display: none;
    }
}
