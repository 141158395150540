#app-footer {
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;
  z-index: 1000;
  min-height: 50px;
}

#app-footer .nav-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

#app-footer .nav-link,
#app-footer span {
  color: #495057;
  padding: 0;
  margin: 0;
}

#app-footer .nav-link:hover {
  color: #FF9800;
  text-decoration: none;
}

.footer-left,
.footer-right {
  display: flex;
  align-items: center;
}

@media (max-width: 500px) {
  #app-footer {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem;
  }

  .footer-left,
  .footer-right {
    width: 100%;
    justify-content: center;
  }

  .footer-right {
    flex-direction: column;
    align-items: center;
  }

  .services-text {
    display: none;
  }
}
