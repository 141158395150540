body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root{
  --primary: #EA7600;
  --secondary:#DFD1A7;
}

.form-control{
  border-radius: 0 !important;
}
.btn{
  border-radius: 0 !important
}
.btn.btn-secondary{
  background-color: var(--secondary);
  border: none;
}
.btn.btn-primary{
  background-color: var(--primary);
  border: none;
}

.card{
  border-radius: 0 !important;
}
.disabled{
  opacity: 0.4 !important;
}
.form-select{
  border-radius: 0 !important;
}