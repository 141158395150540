#app-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 16px;
    align-items: center;
}

#logo-arauco {
    width: 200px;
    height: auto;
}

#app-header>.nav-item {
    display: flex;
    align-items: center;
}

#app-header>.nav-item>.nav-link {
    padding: 0px;
}

#app-header>.nav-item>.nav-link>span {
    color: #0153b3;
    font-weight: 800 !important;
    font-size: x-large !important;
    vertical-align: middle;
}

#logo-usuario {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c5a4f;
    font-size: 30px;
    transition: transform 0.1s, color 0.1s;
}

#logo-usuario:hover {
    border: none !important;
    transform: scale(1.05);
    color: #5a4a3f; /* Un tono más oscuro para el hover */
}

.dropdown.dropup.show button #logo-usuario {
    transform: scale(1.05);
}

/* Si quieres quitar el fondo del botón del dropdown también */
#boton-dropdown-usuario {
    background: none;
    border: none;
    padding: 0;
}

#boton-dropdown-usuario:hover,
#boton-dropdown-usuario:focus,
#boton-dropdown-usuario:active {
    background: none !important;
    box-shadow: none !important;
    border: none !important;
}

#panel-usuario .dropdown .dropdown-menu hr {
    margin: 0;
}

#panel-usuario .dropdown .dropdown-menu {
    padding-block: 0;
}

#panel-usuario .dropdown .dropdown-menu .dropdown-item,
#panel-usuario .dropdown .dropdown-menu .dropdown-header {
    padding-block: 1rem;
}

#panel-usuario .dropdown .dropdown-menu .dropdown-item:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.unclickable {
    pointer-events: none;
}

.unclickable:hover {
    pointer-events: auto;
}

#boton-idioma {
    background: none;
    border: none;
    color: #6c5a4f;
    transition: transform 0.1s, color 0.1s;
    padding: 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 30px;
}

#boton-idioma:hover,
#boton-idioma:focus,
#boton-idioma:active {
    background: none !important;
    box-shadow: none !important;
    transform: scale(1.05);
    color: #5a4a3f;
    border: none !important;
}

.idioma-texto {
    font-size: 16px;
    font-weight: bold;
}

/* Nuevo estilo para alinear los botones de idioma y usuario */
.header-buttons {
    display: flex;
    align-items: center;
    gap: 5px;
}
.dropdown-container {
    position: relative;
  }
  
  .dropdown-button {
    background: none;
    border: none;
    color: #6c5a4f; /* Brownish Gray */
    transition: transform 0.1s, color 0.1s;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 40px; /* Ensure matching height */
  }
  
  .dropdown-content {
    position: absolute;
    top: 50px; /* Consistent opening height */
    right: 0px; /* Align to the right of the button */
    background-color: #FFFFFF;
    border: 1px solid #ddd;
    border-radius: 0px;
    z-index: 9999;
    width: 200px; /* Adjust width as needed */
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap:20;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Optional: Enable scrolling if content overflows */
  }
  
  .dropdown-item {
    border: none;
    padding:20px;
    background: none;
    text-align: left;
    color: #6c5a4f; /* Brownish Gray */
    cursor: pointer;
    font-size: 16px;
  }

  .dropdown-item:hover {
    background-color: #ddd;
  }
  