.local-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.local-spinner .spinner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.local-spinner .custom-spinner {
    color: #FF9800 !important;
}

.local-spinner .spinner-wrapper p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
}