@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

.App {
  height: 100svh;
  width: 100%;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  display: flex;
  flex-flow: column;
}

input:focus,
.form-control:focus {
  border-color: #e6dfd3 !important;
  border-width: 3px !important;
  box-shadow: 0 0 4px rgba(108, 90, 79, 0.2) !important;
  outline: 0 !important;
}

select:focus,
.form-control:focus {
  border-color: #e6dfd3 !important;
  border-width: 3px !important;
  box-shadow: 0 0 4px rgba(108, 90, 79, 0.2) !important;
  outline: 0 !important;
}

/* Cambio de color para el select */
select option:checked,
select option:hover {
  background-color: #e6dfd3 !important;
  color: #4c3a2f !important;
}

/* Cambio de color para las sugerencias (autocomplete) */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #e6dfd3 inset !important;
  -webkit-text-fill-color: #4c3a2f !important;
}