#cambiarPw {
    background-color: #f8f9fa;;
    padding: 1em;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    
}

#cambiarPw>.card {
    max-width: 600px;
    max-height: 700px;
    width: 450px;
    height: auto;
    padding: 2vh;
    border: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#cambiarPw, #titulo-pw {
    color: #4C5058 !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-block: 1em;
    padding-inline: 1em;
    border-bottom: #f8f9fa 1px solid;
    gap: 1em;
}

#cambiarPw #titulo-login #texto-titulo {
    display: flex;
    flex-direction: column;
}

/* #login #titulo-login #texto-titulo #nombre {
    font-size: 1.5em !important;
    font-weight: 800 !important;
} */

/* #login #titulo-login #texto-titulo #aplicativo {
    font-size: 1vmax;
} */



/* #login #recuperar-password {
    width: 100%;
    margin-top: 3em;
    font-size: medium;
    text-align: center;
} */

#cambiarPw input {
    margin-bottom: 1em;
}
